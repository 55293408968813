<div baCardBlur class="animated fadeIn card {{cardType}} {{baCardClass || ''}}graph-border" zoom-in>
    <div *ngIf="title" class="card-header clearfix">
          <div class="col-md-12">
            <span style="font-weight: 600;font-size: 14px; width: 145px;float: left;">{{title}}</span>
            <div style="width: calc(100% - 145px);text-align: right;float: right;">
              <div *ngFor="let currObj of currValuesObj;let i = index;" class="dash-select-box">
                  <select class="form-control" [(ngModel)]="currValuesObj[i]" (ngModelChange)="onCurrValuesChange(i,$event)">
                      <option *ngIf="dropdownSets[i].size==0" selected disabled value="0">-- No Data --</option>
                      <!--<option *ngIf="dropdownSets[i].size > 0" value="0">&#45;&#45; All &#45;&#45;</option>-->
                      <option *ngFor="let value of dropdownSets[i]" [value]="value.id"> {{value.text}} </option>
                  </select>
              </div>
            </div>  
          </div>
         
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
