import { RoutingState } from './catelog/services/routingState.service';
import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';

import { routing }       from './pages.routing';
import { NgaModule } from '../theme/nga.module';

import { Pages } from './pages.component';
import { AuthGuard } from "./auth.guard";
import { AccessableGuard } from "./accessable.guard";
import { WriteableGuard } from "./writable.guard";
import { HideLoaderGuard } from "./hideLoader.guard";
import { GlobalService } from "./global.service";
import { ServiceDisableGuard } from "./serviceDisable.guard";
import { GoodsDisableGuard } from "./goodsDisable.guard";
import { SuperUserGuard } from "./superUser.guard";
import { WsService } from "./ws.service";
import { AppredirectComponent } from './appredirect/appredirect.component';
import { IsLoginGuard } from '../pages/commons/auth/isLogin-guard.service';

@NgModule({
  imports: [
      CommonModule, 
      NgaModule, 
      routing
  ],
  declarations: [
      Pages,
      AppredirectComponent,
  ],
  providers:[
      AuthGuard,
      AccessableGuard,
      WriteableGuard,
      HideLoaderGuard,
      GlobalService,
      ServiceDisableGuard,
      GoodsDisableGuard,
      SuperUserGuard, 
      WsService,
      RoutingState,
      IsLoginGuard
  ]
})

export class PagesModule {
}
