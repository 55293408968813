export const environment = {
    production: false,
    "BUYER_EMAIL": "support@moglix.com",
    "BUYER_NUMBER": "+91 955 5988 544",
    microsoft: {
        CLIENT_ID: '8afa6760-4915-4a03-b572-1c2fa5438200',
        TENANT_ID: '421e9584-87ff-424f-95b8-1bf46b70db99'
    },
    sentry: {
        DSN: 'https://8dab80f98d3349f6ae372687332996d4@sentry.moglix.com/16',
        ORIGIN : 'https://buyersqanew.moglix.com'
    },
    URLS: {
        "WS_URL": "wss://procurementqa1.moglix.com/procurement/",
        "AUTH_URL": "https://buyerauth-qa1.moglilabs.com/login/",
        "PROCUREMENT_URL": "https://procurement-qa1.moglilabs.com/procurement/",
        "DATASYNC_URL": " https://datasync-qa1.moglilabs.com/buyers/",
        "MOGLIX_API_URL": "https://apiqa.moglilabs.com/",
        "DOMAIN": "buyersqa.moglix.com",
        "DEPLOYMENT_MODE": "test",
        "IDENTITY": "BUYERS",
        "INVOICE_URL": "https://invoice.moglilabs.com",
        "EMS_API": "https://emsqa.moglilabs.com/",
        "EMS_API2" : "https://lmsqa.moglix.com/",
        "EMS_API2_ACCESS_KEY" : "84353723-c1cc-4ff5-bfc4-2f33f304cc5e",
        "EMS_WMS" : "https://wms.moglilabs.com/",
        "EMS_WMS_TOKEN" : "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2YWliaGF2LnRoYXBsaXlhbEBtb2dsaXguY29tIiwiZW1haWwiOiJ2YWliaGF2LnRoYXBsaXlhbEBtb2dsaXguY29tIiwidXNlcktleSI6ImVIZ1FxVWl5eUpuQWZsTXNCa2pQRGJMTWJDTHRpWGR1SHZXelNhYVl0TW5kZnRWQ01UbEZwTnBDRm1qcWFCSkUifQ.ET97qIOLi8SABABK5zl3dQbKZMxVwf8Q05wJhBJ8Na4",
        "BUCKET_URL": "stg.moglix.com/",
        "GST_SERVER_URL": "https://gstkb.greengst.com",
        "key": "851176149543-jvmkst8pbvbb5djhg0bdojvucopoak30.apps.googleusercontent.com",
        "GSTNDETAILS_URL": "https://newproductionapi.greengst.com/public_api/getTaxpayerByGstin",
        // "SAP_URL": "https://sapint-qa1.moglilabs.com/",
        "SAP_URL": "https://sapqa.moglilabs.com/",
        "UPDATE_PO": "http://emsqa.moglilabs.com/",
        "EOC_API": "https://buyerintapi-qa1.moglilabs.com/",
        "BUYER_INT_API2": 'https://buyersintapiqa2.moglix.com/',
        'DASHBOARD_API': 'https://analysis.moglix.com/',
        'CONSUMER_API': 'https://buyerconsumer-qa1.moglilabs.com',
        'SALES_API': 'https://salesopsapi-qa1.moglilabs.com/',
        'SALES_API_TOKEN': 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJpbnRlZ3JhdGlvbi5ib3RAbW9nbGl4LmNvbSIsImVtYWlsIjoiaW50ZWdyYXRpb24uYm90QG1vZ2xpeC5jb20iLCJ1c2VyS2V5IjoiRVRNaVRvUldpSldCSXpxWm9rcWxMc09kRXNqaUpOYk1icUpSREl6QnRhT1dkU296Um9OZkVERkdZT2dEQkpGWCJ9.UJALC_hElxM0muDHlLZu26NZYMBs17y7tkz3h2vGfoo',
        'CENTRAL_API': 'https://mce-account-service-qa1.moglilabs.com/account/',
        'CENTRAL_APITOKEN': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJidXllcmludC1idXllcnNAb21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjo3MywiZXhwIjoxNjY3MzkwNjEyLCJpYXQiOjE2MzU4NTQ2MTIsImVtYWlsIjoiYnV5ZXJpbnQtYnV5ZXJzQG9tZ2xpeC5jb20ifQ.jxZR0DcsqjoU8WCWNNp7-RX7c5w8RC5Ro5D7slW1vJqUpIaD2DnHKm5ZKv0axxGJs_3zjFoJijmGc4_srgfFdg',
        "SEARCH_URL": "https://buyer-search-qa1.moglilabs.com/",
        'CLIENT_URL': "https://me-maas-service.moglilabs.com/",
        "CUSTOMER_URL": "https://me-maas-service.moglilabs.com/customer/",
        'CATALOG_URL': 'https://nodeapiqa.moglilabs.com/nodeApi/v1/'
    },
};
