<div class="widgets">
  <div class="row margin-tb">
    <div class="col-xs-12 col-md-8 div-center" *ngIf="!isSearchHide">
      <div class="search-box-cont margin-b">
        <i class="fa fa-search"></i>
        <input #search type="search" placeholder="Search..." (keydown.enter)="onSearch(search.value)">
        <input type="submit" value="Search" (click)="onSearch(search.value)">
      </div>
    </div>
  </div>
    <div class="col-md-12">
      <ng2-smart-table (userRowSelect)="onRowClicked($event)" (selectAll)="onSelectAll($event)" (selectRadio)="onRadioChange($event)" (checkboxChange)="onCheckboxChange($event)" (mouseEnter)="onMouseEnter($event)" (mouseLeave)="onMouseLeave($event)" [settings]="settings" [source]="source"></ng2-smart-table>
    </div>
</div>
