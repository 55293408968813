<ng-container *ngIf="menuItem?.title !== 'Gift Store' || companyId !='11480'">
<li *ngIf="!menuItem.hidden && settings.isDesktop" [title]="menuItem.title"  [ngClass]="{'active': child && menuItem.selected && menuItem.children, 'secondlevel-active': child && menuItem.selected}">
 <!-- Added for new tab target -->
  <a *ngIf="!menuItem.children && !menuItem.url && menuItem.target" (click)="opentTarget(menuItem.href)">
    <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
  </a>
  <!-- Added for new tab target -->
  <a *ngIf="!menuItem.children && !menuItem.url && !menuItem.target" (mouseenter)="onHoverItem($event, item)" [routerLink]="menuItem.route.paths" (click) = "clickEnabled(menuItem)">
      <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
    </a>
  <a *ngIf="!menuItem.children && menuItem.url" (mouseenter)="onHoverItem($event, item)" [href]="menuItem.url" [target]="menuItem.target" (click) = "clickEnabled(menuItem)">
      <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
    </a>                        
  <!-- <a *ngIf="menuItem.children" class="haschildren" (mouseenter)="onHoverItem($event, item)" href (click)="onToggleSubMenu($event, menuItem)"> -->
  <a *ngIf="menuItem.children" class="haschildren active" data-toggle="collapse" href="{{'#demo' + itemNumber}}" (mouseenter)="onHoverItem($event, item)">
      <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }} <i class="ri-arrow-down-s-line"></i></span>
  </a>
  <ul *ngIf="menuItem.children" class="second-levelmenu collapse" [ngClass]="{'in': menuItem.expanded}" id="{{'demo' + itemNumber}}">
    <menu-item [menuItem]="subItem" [child]="true" (itemHover)="onHoverItem($event)" (toggleSubMenu)="onToggleSubMenu($event, subItem)"
    *ngFor="let subItem of menuItem.children" (click) = "clickEnabled(subItem)"></menu-item>
</ul>
  <a *ngIf="menuItem.children" class="haschildren inactive" (click)="shownext($event)">
    <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }} <i class="ri-arrow-down-s-line"></i></span>
  </a>

  <ul *ngIf="menuItem.children" class="second-levelmenu iconhover">
    <menu-item [menuItem]="subItem" [child]="true" (itemHover)="onHoverItem($event)" (toggleSubMenu)="onToggleSubMenu($event, subItem)"
      *ngFor="let subItem of menuItem.children" (click) = "clickEnabled(subItem)"></menu-item>
  </ul>
</li>

<!-- mobile -->
<li *ngIf="!menuItem.hidden && menuItem.mobile && !settings.isDesktop" [title]="menuItem.title"  [ngClass]="{'active': child && menuItem.selected && menuItem.children, 'secondlevel-active': child && menuItem.selected}">

  <!-- Added for new tab target -->
  <a *ngIf="!menuItem.children && !menuItem.url && !menuItem.target" (mouseenter)="onHoverItem($event, item)" [routerLink]="menuItem.route.paths" (click) = "clickEnabled(menuItem)">
      <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
    </a>
  <a *ngIf="!menuItem.children && menuItem.url" (mouseenter)="onHoverItem($event, item)" [href]="menuItem.url" [target]="menuItem.target" (click) = "clickEnabled(menuItem)">
      <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }}</span>
    </a>                        
  <!-- <a *ngIf="menuItem.children" class="haschildren" (mouseenter)="onHoverItem($event, item)" href (click)="onToggleSubMenu($event, menuItem)"> -->
  <a *ngIf="menuItem.children" class="haschildren active" data-toggle="collapse" href="{{'#demo' + itemNumber}}" (mouseenter)="onHoverItem($event, item)">
      <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i><span>{{ menuItem.title }} <i class="ri-arrow-down-s-line"></i></span>
  </a>
  <ul *ngIf="menuItem.children" class="second-levelmenu collapse" [ngClass]="{'in': menuItem.expanded}" id="{{'demo' + itemNumber}}">
    <menu-item [menuItem]="subItem" [child]="true" (itemHover)="onHoverItem($event)" (toggleSubMenu)="onToggleSubMenu($event, subItem)"
    *ngFor="let subItem of menuItem.children" (click) = "clickEnabled(subItem)"></menu-item>
</ul>

</li>
</ng-container>