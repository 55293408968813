
  <div class="dropdown notify" (click)="openNotifications()">
    <a href class="dropdown-toggle" id="msg-dd1" data-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-bell"></i><span *ngIf="notifications.length">{{totalNotifications}}</span>

      <div class="notification-ring"></div>
    </a>

    <div class="top-dropdown-menu dropdown-menu" aria-labelledby="msg-dd1">
      <i class="dropdown-arr"></i>

      <div class="header clearfix">
        <strong>Notifications</strong>
        <!--<a href>Mark All as Read</a>-->
        <!--<a href>Settings</a>-->
      </div>
      <div class="msg-list">
        <a *ngFor="let msg of notifications" [routerLink]="msg.url" [queryParams]= "msg.params" class="clearfix">
          <div class="title-area"><strong>{{ msg.title }}</strong></div>
          <div class="msg-area">
            <div>{{ msg.text }}</div>
            <!--<a href="#"><span>{{ msg.time }}</span></a>-->
            <!--<a [routerLink]="msg.url" [queryParams]= "msg.params" ><span>View</span></a>-->
          </div>
        </a>
      </div>
      <!--<a href>See all notifications</a>-->
    </div>
  </div>
  <!--<li class="dropdown">-->
    <!--<a href class="msg dropdown-toggle" id="msg-dd2" data-toggle="dropdown" aria-expanded="false">-->
      <!--<i class="fa fa-envelope-o"></i><span>5</span>-->
      <!--<div class="notification-ring"></div>-->
    <!--</a>-->
    <!--<div class="top-dropdown-menu dropdown-menu" aria-labelledby="msg-dd2">-->
      <!--<i class="dropdown-arr"></i>-->
      <!--<div class="header clearfix">-->
        <!--<strong>Messages</strong>-->
        <!--<a href>Mark All as Read</a>-->
        <!--<a href>Settings</a>-->
      <!--</div>-->
      <!--<div class="msg-list">-->
        <!--<a *ngFor="let msg of messages" href class="clearfix">-->
          <!--<div class="img-area"><img [ngClass]="{'photo-msg-item': !msg.image}"-->
                                     <!--src="{{ ( msg.image ||  (msg.name | baProfilePicture)) }}"></div>-->
          <!--<div class="msg-area">-->
            <!--<div>{{ msg.text }}</div>-->
            <!--<span>{{ msg.time }}</span>-->
          <!--</div>-->
        <!--</a>-->
      <!--</div>-->
      <!--<a href>See all messages</a>-->
    <!--</div>-->
  <!--</li>-->
