<div class="mogb_paginationrow clearfix" [ngClass] = "{'withMargin': giveMargin}" *ngIf="count > pageSize">
    <span>Showing {{getPageStart()}} - {{getPageEnd()}} of {{count}} </span>
    <ul class="mogb_pagination">
        <li [class]="pageNumber>1?'active':'non-active'" (click)="setPageByControl('FIRST')"
            *ngIf="displayPageControls()">
            <span>First</span>
        </li>
        <!-- <li [class]="pageNumber>1?'active':'non-active'" (click)="setPageByControl('PREV')"
            *ngIf="displayPageControls()">
            <span>Prev</span>
        </li> -->
        <li *ngFor="let page of pages;index as i;" [class]="pageNumber == (i+1)?'active':'non-active'"
            (click)="setPageByValue(i+1)"
            [style.display]="(i+1)>=pageDisplay &&  maxPagesToDisplay>=(i+1)?'inline-block':'none'">
            <span>{{page}}</span>
        </li>
        <!-- <li [class]="totalPages>pageNumber?'active':'non-active'" (click)="setPageByControl('NEXT')"
            *ngIf="displayPageControls()">
            <span>Next</span>
        </li> -->
        <li [class]="totalPages>pageNumber?'active':'non-active'" (click)="setPageByControl('LAST')"
            *ngIf="displayPageControls()">
            <span>Last</span>
        </li>
    </ul>
</div>