import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GlobalState } from '../../../global.state';
import { DataService } from '../../../pages/data.service';
import { BaHeaderService } from '../../../theme/services/baHeader/baHeader.service';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { params } from '../../../pages/params';
import { urls } from '../../../pages/urls';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import {environment} from "../../../../environments/environment";
import { CommonapiService } from '../../../pages/commonapi.service';
import { AppSettings, Settings } from '../../../app.settings';
import * as moment from 'moment';

declare var dataLayer: any;
declare var $: any 
declare var jQuery: any;
@Component({
    selector: 'ba-header',
    templateUrl: 'baHeader.html',
    styleUrls: ['baHeader.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaHeader {
    allCompaniesList: any[] = [];
    pageUri: string;
    splitted: any;
    buyerId: any;
    companyId: any;
    activeCompany: any;
    activeBranch: any;
    branchNames: any;
    companyNames: any;
    selectedPlant: any;
    selectedCompany: any;
    isExpand: any = false;
    searchPlantInput: FormControl = new FormControl('', []);
    searchCompanyInput: FormControl = new FormControl('', []);
    vendorLogo: any;
    branchType: any;
    showSearchBar: any = false;
    productReq: any;
    totalProducts: any;
    searchSuggessions: any[] = [];
    selectedProduct: any = '';
    isSAPIntegrated: any = false;
    isSAPHybrid: any = false;
    rightLogoImage: any = '';
    isPoWriteAccess: any = true;

    grid:any;
    masonryOptions = {
        itemSelector: '.grid-item',
        columnWidth: 0
    };
    
    public settings: Settings;
    showList = false;
    showGS: boolean;

    constructor(private router: Router, private _state: GlobalState,
         private _dataService: DataService, private _baHeaderService: BaHeaderService, private _activatedRoute: ActivatedRoute, private _location: Location,
          private _commonapiService: CommonapiService,
          public _appSettings:AppSettings
          ) {
            this.settings = this._appSettings.settings;
            router.events.subscribe((val) => {
                if (val instanceof NavigationEnd) {
                    if(this.allCompaniesList.length > 0) { this.createCompanyListBasedOnUrl(); }
                }
            });
            this._activatedRoute.url.subscribe(() => {
                this.displaySearchBar();
            })
            this._baHeaderService.clearSearchSubject.subscribe(() => {
                this.selectedProduct = '';
            });
            this._dataService.changeNotifyLogo$.subscribe(
                value => {
                    if(value) {
                        this.getVendorLogo('');
                    }
                });
    }

    ngOnInit() {
        this.companyId = this._dataService.getBuyerCompanyId();
        this.buyerId = this._dataService.getBuyerPlantId();
        dataLayer.push({'event': 'changeCompany', 'compId': this.companyId});
        dataLayer.push({'event': 'changePlant', 'plantId': this.buyerId});
        this.branchNames = this._dataService.getSessionObj()['branchNames'];
        this.companyNames = this._dataService.getSessionObj()['companyNames'];
        this.getCompanyData();
        this.setActiveCompAndPlant(this.companyId, this.buyerId);
        this.checkMediaScreen();
    }

    checkMediaScreen(){
        if(this.settings.isDesktop){
            $('.mgb_sidebar').removeClass('inactive').addClass('active');
        }else{
            $('.mgb_sidebar').removeClass('active').addClass('inactive');
        }
    }

    displaySearchBar() {
        this.showSearchBar = this._location.path().includes("catalogue") ? true : false;
    }

    makeProductReq(key: any) {
        this.productReq = {
            plantId: this.selectedPlant,
            companyId: this.selectedCompany.toString(),
            corporateClient: this._dataService.isCorporateClientGlobal,
            multiSearchRequest: {
                searchKey: key,
            }
        }
    }

    searchProducts(event ?: any) {
        if (!event || event.keyCode === 13) {
            this.router.navigateByUrl("pages/catalogue/all-products");
            event ? this._baHeaderService.setSearchKey(event.target.value) : this._baHeaderService.setSearchKey(this.selectedProduct);
            this.totalProducts = 0;
            return;
        }
        if (event.keyCode === 27) {
            this.router.navigateByUrl("pages/catalogue/all-products");
            this.totalProducts = 0;
            return;
        }
        this.makeProductReq(event.target.value);
        this.searchSuggessions = [];
        this.showList = false;
        this._dataService.callRestful('POST', environment.URLS.EOC_API + urls.PRODUCT.FETCH_ALL_ES_PARTIAL, this.productReq).subscribe(
            (data) => {
                if (data['code'] === 200) {
                    this.totalProducts = data['data']['totalCount'];
                    this.searchSuggessions = data['data']['result'];
                    this.setExpiryDate();
                    this.showList = true;
                    // this.buildSearchSuggessions(data['data']['result']);
                } else {
                    this._dataService.showMessage('error', data['message']);
                }
            },
        );
    }

    setExpiryDate(){
        let currentTime = Date.now();
        this.searchSuggessions = this.searchSuggessions.map((data) => {
            data['expiryDate'] = data?.arcValidityTo ? moment(data.arcValidityTo).format("DD MMM YYYY") : '';
            data['isExpired'] = data?.arcValidityTo && data.arcValidityTo < currentTime ? true : false;
            return {
                ...data
            }
        })
    }

    buildSearchSuggessions(data: any){
        this.searchSuggessions = [];
        for (let i = 0; i < data.length; i++) {
            this.searchSuggessions.push(data[i].name);
        }
    }

    selectProduct(product: any) {
        this.showList = false
        if(product && product?.supplementaryProducts) {
            this.router.navigateByUrl('/pages/catalogue/product-detail?id=' + encodeURIComponent(product.id) + '&sp=true' + '&isExpired=' + product.isExpired + '&expiryDate=' + product.expiryDate);
        } else {
            this.router.navigateByUrl('/pages/catalogue/product-detail?id=' + encodeURIComponent(product.id) + '&isExpired=' + product.isExpired + '&expiryDate=' + product.expiryDate);
        }
        // this.selectedProduct = product;
        // this.totalProducts = 0;
        // this._baHeaderService.setSearchKey(product);
    }

    preventDefault(e) {
        e.preventDefault();
    }

    hideDropdown() {
        this.totalProducts = 0;
    }

    createCompanyListBasedOnUrl() {
        if (this._location.path() !== '/pages/catalogue/all-products' &&
            this._location.path() !== '/pages/catalogue/product-detail' &&
            this._location.path() !== '/pages/orders/po/cart' &&
            this._location.path().indexOf('/pages/orders/po/create') === -1 && 
            this._location.path() !== '/pages/pm/product-edit' &&
            this._location.path() !== '/pages/pm/product-upload' &&
            this._location.path() !== '/pages/account/detail') {
                if (this.allCompaniesList[0] && this.allCompaniesList[0].plants[0].plantId !== '-1') {
                    for (let i = 0; i < this.allCompaniesList.length; i++) {
                        this.allCompaniesList[i].plants.unshift({
                            plantId : '-1',
                            plantName : 'All Plants'
                        });
                    }
                }
        } else {
            if (this.allCompaniesList[0] && this.allCompaniesList[0].plants[0].plantId === '-1') {
                if (this._dataService.getBuyerPlantId() === '-1') {
                    this.companyId = this._dataService.getCompanyId();
                    this.buyerId = this._dataService.getDefaultBranchForCompany(this.companyId).toString();
                    this.branchNames = this._dataService.getSessionObj()['branchNames'];
                    this.companyNames = this._dataService.getSessionObj()['companyNames'];
                    this._dataService.setCompanyId(this.companyId);
                    this._dataService.setBuyerPlantId(this.buyerId);
                    this.setActiveCompAndPlant(this.companyId, this.buyerId);
                }
                for (let i = 0; i < this.allCompaniesList.length; i++) {
                    this.allCompaniesList[i].plants.shift();
                }
            }
        }
    }

    public getCompanyData(query?: any) {
        const reqObj = {
            'searchString' : query ? query : ''
        }
        this._dataService.callRestful('POST', environment.URLS.AUTH_URL + urls.SEARCHCOMPANY.SEARCHCOMPANY, reqObj).subscribe(
            (data) => {
              this.allCompaniesList = [];
              if (data.status && data.data) {
                this.allCompaniesList = data.data;
                this.createCompanyListBasedOnUrl();
              }
            }
        );
    }

    public getPlantData(query: any) {
        const reqObj = {
            'searchString': query
        }
        this._dataService.callRestful('POST', environment.URLS.AUTH_URL + urls.SEARCHBRANCH.SEARCHBRANCH, reqObj).subscribe(
            (data) =>
            {
                this.allCompaniesList = [];
                if (data.status) {
                    this.allCompaniesList = data.data;
                    if (this._location.path() !== '/pages/catalogue/all-products' &&
                    this._location.path() !== '/pages/catalogue/product-detail' &&
                    this._location.path() !== '/pages/orders/po/cart' &&
                    this._location.path().indexOf('/pages/orders/po/create') === -1 &&
                    this._location.path() !== '/pages/pm/product-edit' &&
                    this._location.path() !== '/pages/pm/product-upload') {
                        for (let i = 0; i < this.allCompaniesList.length; i++) {
                            this.allCompaniesList[i].plants.unshift({
                                plantId: '-1',
                                plantName: 'All Plants'
                            });
                        }
                    }
                }
            }
        );
    }

    public setActiveCompAndPlant(compId: any, plantId: any) {
        this.activeCompany = {
            'id': compId,
            'text': this.companyNames[compId]
        };
        if(plantId === '-1') {
            this.activeBranch = {
                'id': plantId,
                'text': this.activeCompany.text + '-All Plants'
            };
        } else {
            this.activeBranch = {
                'id': plantId,
                'text': this.branchNames[plantId]
            };
        }
        this.selectedPlant = plantId;
        this.selectedCompany = compId;
        this._dataService.setSelectedBranchIds(plantId);
        this._dataService.setCookie('plantId', plantId, 1);
        let buyerIdArray = this._dataService.getAllPlantsByCurrCompany(this.selectedCompany);
        this._dataService.setCookie('selectedBranchIds', buyerIdArray, 1);
        this.getVendorLogo(plantId);
        if (plantId && plantId !== -1) { this.fetchCart() } else {this._dataService.setCartCount(0)};
        this._commonapiService.getSelectedCompDetails(plantId);
    }

    public setActivePlant(plantId: any) {
        if (plantId === '-1') {
            this.activeBranch = {
                'id': plantId,
                'text': this.activeCompany.text + '-All Plants'
            };
            this._dataService.setCartCount(0);
        } else {
            this.activeBranch = {
                'id': plantId,
                'text': this.branchNames[plantId]
            };
            this.fetchCart();
        }
        this.selectedPlant = plantId;
        this.getBranchAccess(plantId);
    }

    public getVendorLogo(plantId) {
        $('#loader').css('display', 'block');
        const reqObj = {
            'idCompany': this.selectedCompany,
        };
        this._dataService.callRestful('POST', environment.URLS.AUTH_URL + urls.AUTH.GET_IS_SAP_INTREGATED, reqObj).subscribe(
            (data) => {
                $('#loader').css('display', 'none');
                // console.log('data', data);
                if (data['successful']) {
                    this.settings.company = data?.data ? data.data : {};
                    localStorage.setItem('umFlag', data?.data?.company?.umAssigned.toString());
                    if(plantId){
                        this.getBranchAccess(plantId);
                    }
                    this._dataService.setIsCorporateClient(data.data.company.isCorporateClient);
                    this.vendorLogo = data.data.company.vendor_logo;
                    this.isSAPIntegrated = data.data.company.isSapIntg;
                    this._dataService.setIsSapIntg(this.isSAPIntegrated);
                    this.isSAPHybrid = data.data.company.isSapHybrid;
                    this.rightLogoImage = (data.data.company.logo) ? data.data.company.logo : '';
                    this._dataService.setSapTenantId(data.data.company.sapTenantId);
                    this._dataService.setPoEditFlag(data.data.company.poEdit);
                    const costFactorEnabled = data.data.company.costFactorEnabled;
                    const orderLimitEnabled = data.data.company.orderLimitEnabled;
                    const orderUpperLimit = data.data.company.orderUpperLimit;
                    const orderLowerLimit = data.data.company.orderLowerLimit;
                    const costCentres = data.data.company.costCentres;
                    const nonEditableSp = data.data.company.nonEditableSp;
                    const companySettings = {
                        'costFactorEnabled': costFactorEnabled, 'orderLimitEnabled': orderLimitEnabled,
                        'orderUpperLimit': orderUpperLimit, 'orderLowerLimit': orderLowerLimit, 'costCentres': costCentres,
                        'nonEditableSp': nonEditableSp
                    };
                    this._dataService.setCompanySettings(companySettings);
                }
            }
        );
    }

    public changePlant(id: any, plantName?: any, company?: any) {
        this.companyNames = this._dataService.getSessionObj()['companyNames'];
        this.branchNames = this._dataService.getSessionObj()['branchNames'];
        if (id !== '-1') {
            this._dataService.setBuyerPlantId(id);
            dataLayer.push({'event': 'changePlant', 'plantId': id});
            if (!this.selectedCompany) { this._dataService.setCompanyId(company.company); }
        } else {
            this._dataService.setCompanyId(company.company);
            this._dataService.setBuyerPlantId(id);
        }
        if (this.selectedCompany === (company.company).toString()) {
            this.setActivePlant(id);
        }
        else {
            dataLayer.push({'event': 'changeCompany', 'compId': company.company});
            dataLayer.push({'event': 'loginEmail', 'emailId': this._dataService.getUserEmail()});
            this._dataService.setCompanyId(company.company);
            this.setActiveCompAndPlant(company.company, id);
        }
        // this.getBranchAccess(id);
        //$( () => { $('[data-toggle="tooltip"]').tooltip()})
        $('#plantselectpopup').modal('hide');
    }

    public getBranchAccess(plantId: any) {
        this._commonapiService.getAllUsersList();
        this._commonapiService.getUsersListByBranch();
        let req = {
          'idBranch': (plantId === '-1') ? null : plantId,
          'idUser': this._dataService.getUserId()
        }
        req['plantIds'] = this._dataService.getAllPlantsByCurrCompany(this.selectedCompany);
        this._dataService.callRestful('POST', environment.URLS.AUTH_URL + this._commonapiService.getUrls().UM.AUTH.GET_BRANCH_ACCESS, req).subscribe(
          (data) => {
            if(data['successful']) {
              this.branchType = data['data']['branchModules']['branchType'];
              this._dataService.setModuleAccess(data['data']['branchModules']['modulesPermission']);
              this.isPoWriteAccess = data['data']['branchModules']["modulesPermission"]["PO"]  >= 2 ? true : false;
              this.showGS = data['data']['branchModules']["modulesPermission"]["GS"]  >= 1 ? true : false;
            }
            // else{
            //   this._dataService.showMessage("error", data['message']);
            // }
          }
        );
    }

    public getSearchResults(data: any , event: any, action: any) {
        if (event.keyCode === 13 || event.type === 'click') {
          let query: any;
          data['value'] === '' ? query = '' : query = data['value'];
          if (action === 'plantChange') {
            this.getPlantData(query);
            this.isExpand = true;
          }
          else {
            this.searchPlantInput.setValue('');
            this.getCompanyData(query);
            this.isExpand = false;
          }
        }
      }

      fetchCart() {
        this._dataService.callRestful('GET', environment.URLS.PROCUREMENT_URL + urls.CART.FETCH_CART + urls.CART.PO).subscribe(
          (rawData) =>
          {
              const data = rawData;
              if (data['status_code'] === 1) {
                this._dataService.setCartCount(data['cart']['count']);
              } else {
                  alert(data['error_msg']);
              }
          }
        );
      }

      goToLandingPage() {
        if(this.settings.isDesktop){
            console.log('settings');
            this.router.navigateByUrl('/pages/orders/po')
        }
      }

      toggleSidebar(){
        this.grid = $('.grid').masonry(this.masonryOptions );
        this.grid.masonry('destroy'); 
        setTimeout(()=>{
        this.grid.masonry( this.masonryOptions ); 
        }, 1000)
      }

      giftVoucher(){
        dataLayer.push({ 'event': 'eGiftVoucherClick' });
      }  

}
