<aside class="al-sidebar" (mouseleave)="hoverElemTop=outOfArea" sidebarResize>
  <ul id="al-sidebar-list" class="al-sidebar-list">
      <!-- <ul id="al-sidebar-list" class="al-sidebar-list" baSlimScroll [baSlimScrollOptions]="{height: menuHeight}"> -->
    <!--<select *ngIf="plantIds" #plantId (change)="changePlantId(plantId.value)">-->
      <!--<option *ngFor="let id of plantIds" value="{{id}}">{{id}}</option>-->
    <!--</select>-->
    <!--<div *ngIf="branches.length>1" class="select-box-cont">-->
      <!--<span class="selected-value clearfix" (click)="toggleDropdown()"></span>-->
      <!--<ul class="select-value">-->
        <!--<li *ngFor="let branch of branches" (click)="changePlantId(branch);">{{branch}}</li>-->
      <!--</ul>-->
    <!--</div>-->
    <ba-menu-item
      [menuItem]="item"
      (itemHover)="hoverItem($event)"
      (toggleSubMenu)="toggleSubMenu($event)"
      *ngFor="let item of menuItems"></ba-menu-item>
  </ul>
  <div class="sidebar-hover-elem" [ngStyle]="{top: hoverElemTop + 'px', height: hoverElemHeight + 'px'}"
       [ngClass]="{'show-hover-elem': showHoverElem }"></div>

    <div class="al-footer-main clearfix">
      <div class="al-copy">&copy;2018 <a href="http://business.moglix.com">Moglix</a></div>
      <div class="al-copy">&nbsp;&nbsp;v{{version}}</div>
    </div>
</aside>
