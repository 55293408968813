<div class="mes_support_icon" (click)="togglechatbox()">
    <span><i class="ri-chat-3-fill"></i></span>
</div>
<div class="mes_supportouterbox" *ngIf="openchat">
    <h4>Moglix Support <span (click)="closechatbox()"><i class="ri-close-circle-fill"></i></span></h4>
    <div class="mes_supportinnerbox">
        <!--- no query box-->
        <div *ngIf = "!chatDataPresent && noQuery && !moglixSupport">
            <div class="mes_supportnoquery"><p>No query raised yet</p>
            <img src="/assets/img/no-query.png" class="noqueryimg">
            <p>If you have query, ask us...</p>
            <button class="mogb_btn primarybtn" (click) = "toggleQuery()">Raise Query</button>
            </div>
        </div>
        <!--- no query box-->
        <!-- ask query box-->
        <div class="messagequerybox" *ngIf = "chatDataPresent || !noQuery"> 
            <div class="anyquery" *ngIf = "!noQuery && !moglixSupport">
                <textarea [(ngModel)] = "query" placeholder="Have a query, ask us.."></textarea>
                <button class="mogb_btn primarybtn" (click) = "upsertQuery()">Submit</button>
            </div>
            <div class="requestresponsebox" *ngIf = "chatDataPresent">
                <ul class="chatarea" id = "chatArea" #scrollMe (scroll)="onScroll()" [ngClass]="{'withoutquerybox': noQuery && moglixSupport }">
                    <li *ngFor = "let data of chatData"><div class="name_email">
                        <span>{{ data.creatorAcronym }}</span> {{ data.creatorName }}<strong>{{ data.creatorId }}</strong></div>
                        <div class="ch_inner">
                            <h5>{{ data.question }}<time>{{ data.creationDate }} | {{ data.creationTime}}</time></h5>
                            <div *ngIf = "anslength > 0">
                                <div class="mog_supportreply" *ngFor = "let ans of data.replyArray">
                                    <h6>{{ ans.userName }}</h6>
                                    <p>{{ ans.chat }}<time>{{ ans.creationdate | date:'dd/MM/yyyy | hh:mm a'}}</time></p>
                                </div>
                            </div>
                            <div class="commentbox" *ngIf = "commentBox && data.id === selectedChatId">
                                <textarea [(ngModel)] = "comment" placeholder="Leave a comment"></textarea>
                                <button class="mogb_btn primarybtn" (click) = "submitReply(data)">Submit</button>
                            </div>
                            <button *ngIf = "data.id !== selectedChatId && !customerReply" class="replybtn replybtn1" (click) = "openCommentBox($event, data)">Reply</button>
                            
                            <div class="buttons">                            
                            <button class="replybtn" *ngIf = "data.id !== selectedChatId && customerReply" (click) = "openCommentBox($event, data)">Reply</button>
                            </div>
                        </div>
                    </li>
                    <!-- <li><div class="name_email">
                        <span>AS</span>Anuj Singh<strong>anuj_singh@gmail.com</strong></div>
                        <div class="ch_inner">
                            <h5>Status of Order ID: 4501884566?<time>11:25 AM</time></h5>
                            <button class="replybtn">Reply</button>
                        </div>
                    </li> -->
                </ul>
                <div class="queryformbox" id = "queryBox" *ngIf = "!moglixSupport" >
                    <textarea placeholder="Have a query, ask us.." [(ngModel)] = "query" (focus) = "enlargeQueryBox()"  class="textarea"></textarea>
                    <button style = "display:none" class="mogb_btn primarybtn" id = "bottomBtn" (click) = "upsertQuery()">Submit</button>
                </div>
            </div>    
        </div>
    </div>
</div>