<div [ngClass]="{'hide': !showMMSearchBar}" class="mogb_searchboxinline">
    <div class="mogb_searchbox">
      <input placeholder="Search product here…" #MMinput [value]="settings.mmSearchValue" (click)="onClickInput()">
      <span class="searchicon"><i class="ri-search-line"></i></span>
    </div>

     <section class="bMainSearchFilter" *ngIf="showList && (suggestionList?.suggestionList?.length || suggestionList?.categorySuggestionList?.length)">
            
      <ng-container *ngIf="suggestionList?.suggestionList?.length">
              <div class="topHeadTitle"><span class="lft">Popular Suggestions</span></div>
              <ul class="listSrchWrap">
                <li *ngFor="let suggestions of suggestionList?.suggestionList" (click)="openLink(suggestions?.term)">
                  <span class="lft">{{suggestions?.term}}</span>
                  <span class="rht">
                    <span>{{suggestions?.productCount}}</span>
                    <i class="ri-external-link-fill"></i></span>
                </li>
              </ul>
            </ng-container>

            <div class="topCategoriesWrap" *ngIf="suggestionList?.categorySuggestionList?.length">
              <span class="titleTxt"><i class="ri-bar-chart-fill"></i>Top Categories</span>
              <div class="listTopCategories">
                <div class="common" style="cursor:pointer;" *ngFor="let category of suggestionList?.categorySuggestionList" (click)="viewCategory(category?.categoryId)">
                  <img src = "https://cdn.moglix.com/{{category?.imageLink}}" default="assets/img/no_img350.jpg" class="mar-r-5 icon-led-lighting">
                  <span>{{category?.categoryName}}</span>
                </div>
              </div>
            </div>

          </section> 
</div>    