
<div *ngIf="pager.pages.length > 1" class="align-left paginateWrap clearfix">
  <span style="display: inline-block; margin-top: 10px;">Showing {{pager.startItem}}-{{pager.endItem}} of {{pager.totalItems}}</span>
    <div *ngIf="pager.pages && pager.pages.length" class="pagination" style="float: right;">      
        <span [ngClass]="{disabled:pager.currentPage === 1}">
          <a (click)="setPage(1)">First</a>
        </span>
        <span [ngClass]="{disabled:pager.currentPage === 1}">
          <a (click)="setPage(pager.currentPage - 1)">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </a>
        </span>
        <span *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
          <a (click)="setPage(page)">{{page}}</a>
        </span>
        <span [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
          <a (click)="setPage(pager.currentPage + 1)">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </a>
        </span>
        <span [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
          <a (click)="setPage(pager.totalPages)">Last</a>
        </span>
    </div>

</div>
